<template>
  <div>
    <Pane />

    <a-card class="container">
      <private-button path="add" type="primary" size="default">
        <span>新建</span>
      </private-button>

      <Padding />

      <a-table
        bordered
        :dataSource="areaList"
        :loading="loading"
        :pagination="false"
      >
        <a-table-column
          key="areaName"
          title="区域"
          data-index="areaName"
        ></a-table-column>
        <a-table-column
          title="负责人"
          data-index="areaCharger"
        ></a-table-column>

        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <private-button path="edit" :params="{ id: text.id }">
              <span>编辑</span>
            </private-button>

            <private-button
              path="delete"
              routeType="method"
              danger
              @clickButton="deleteText(text)"
              v-if="
                (text.children && text.children.length === 0) || !text.children
              "
            >
              <span>删除</span>
            </private-button>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { remove } from "@/api/setting/area";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState("area", ["rawList", "areaList"]),
  },

  mounted() {
    this.loading = true;
    this.getAreaList()
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  methods: {
    ...mapActions("area", ["getAreaList"]),

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这个区域吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
